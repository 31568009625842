/* @import url('https://fonts.googleapis.com/css?family=B612+Mono|Cutive+Mono|PT+Mono|Roboto+Mono&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=PT+Mono&display=swap');

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.App.login,
.App.loading,
.App.waitlisted {
  background-image: url('/images/lysander-yuen-1361159-unsplash-medium.jpg');
  background-size: cover;
  background-position: center;
}

.App .main.container {
  padding-top: 2.8em;
  padding-bottom: 1.5em;
}

.App.login .main.container,
.App.waitlisted .main.container {
  padding-top: 7em;
}

.App .menu .header .logo {
  height: 25px;
  width: 'auto';
  margin-right: 5px;
}

.App.login .menu .header,
.App.waitlisted .menu .header {
  margin: auto;
  padding: 0.5em;
  font-size: 1.8em;
}

.App.login .menu .header .logo,
.App.waitlisted .menu .header .logo {
  height: 40px;
  margin-right: 10px;
  margin-top: 1px;
}

a.ui.segment {
  display: block;
}

.rightAlignedInput,
.ui.input.rightAlignedInput > input {
  text-align: right;
}

.ui[class*='corner labeled'].icon.input.unitLabelled:not([class*='left corner labeled'])
  > input {
  padding-right: 1em !important;
}

.ui.input.unitLabelled > .ui.corner.label {
  border-color: rgba(0, 0, 0, 0);
  bottom: -2.7em;
  top: auto;
  padding-right: 1.6em !important;
  color: rgba(0, 0, 0, 0.25);
  width: unset;
}

.ui.unstackable.items.athleteList > .item > .image,
.ui.unstackable.items.athleteList > .item > .image > img {
  width: 60px !important;
}

.ui.unstackable.items.athleteItem > .item > .image,
.ui.unstackable.items.athleteItem > .item > .image > img {
  width: 80px !important;
}

.ui.unstackable.items.athleteItem > .item > .content {
  margin-top: 0.6em;
}

.ui.unstackable.items.athleteItem > .item > .content.tagged {
  margin-top: 0;
}

/* .ui.accordion.filter-active > .title { */
.ui.styled.accordion.filter-active .active.title,
.ui.styled.accordion.filter-active .title,
.ui.styled.accordion.filter-active .title:hover {
  background-color: rgba(253, 201, 107, 0.11);
}

.ui.segment.archived,
tr.archived {
  background-color: rgb(242, 242, 242);
}

/* 
 *  Remove icon from date field
 */
.field.date-filter .ui[class*='left icon'].input > input {
  padding-left: 1em !important;
}
.field.date-filter i.calendar.icon {
  display: none;
}

/*
 *
 */
.mono,
td.mono,
.ui.input.mono > input {
  font-family: 'PT Mono', monospace;
}

/*
 * Menu overrides
 */

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
  background: none;
}

.item.main-menu > .text,
a.item.main-menu {
  font-size: 0.85em;
}

.filter .field .ui.multiple.search.dropdown > input.search {
  font-size: 1.2rem;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.filter .field .ui.multiple.search.dropdown > .ui.label {
  font-size: 1rem;
}
